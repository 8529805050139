import React from "react";
import Navbar from '../Components/Navbar';
import stylesMonkey from '../CSS/Monkey.module.css';
import styles from '../CSS/PreviousReanimated.module.css';
import banner from '../Pages/Media/MonkeyReanim_LOGO.png';
import qrCode from '../Pages/Media/qr placeholder.jpg';
import Footer from "../Components/Footer";
import gangImg from './/Media/gang1.png';
import catFishImg from './/Media/catfish.png';
import volcano from './/Media/bg1.png';
import poster from './/Media/monkeyreanim_screening_WIDE.png';

import screenCap0 from '../Pages/Media/Screencaps/Monkey/S104_Kenilem.png'
import screenCap1 from '../Pages/Media/Screencaps/Monkey/S125_EugeneChung.png'
import screenCap2 from '../Pages/Media/Screencaps/Monkey/S137_SiannLau.png'
import screenCap3 from '../Pages/Media/Screencaps/Monkey/S209_BrycePemberton.png'
import screenCap4 from '../Pages/Media/Screencaps/Monkey/S306_RicardoGomez.png'
import screenCap5 from '../Pages/Media/Screencaps/Monkey/S314_HanNguyen.png'
import screenCap6 from '../Pages/Media/Screencaps/Monkey/S412_LuisLagman.png'
import screenCap7 from '../Pages/Media/Screencaps/Monkey/S430_DiogoAlves.png'

const Monkey = () => { return(
        <>
            <Navbar/>

            <img id={styles.banner} src={banner} alt="Banner for monkey reanimated" draggable='false'></img>

            <iframe className={styles.video} id={styles.spiderVid} src="https://www.youtube.com/embed/hvQG_62W3wM?si=lrLt9jW--_UHplk2" title="Monkey Reanimated" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>

            <h1 id={styles.heading}>Animators</h1>
            <table>
                <tbody>
                    <tr><td className={styles.creditName}>Wilton Lander</td><td className={styles.creditSocial}>@wilton.anim</td></tr>
                    <tr><td className={styles.creditName}>Rita Kara</td><td>@ritakaracreative, ritak.com.au</td></tr>
                    <tr><td className={styles.creditName}>Poppy Owens</td><td>@poppy_owens</td></tr>
                    <tr><td className={styles.creditName}>Ali Mai</td><td>@blububu03, @BlubTheDrowningFish</td></tr>
                    <tr><td className={styles.creditName}>Michael Kenny</td><td>@kenilem</td></tr>
                    <tr><td className={styles.creditName}>Benjamin Mansur</td><td>@benjaminmansurart</td></tr>
                    <tr><td className={styles.creditName}>Andrew Brown</td><td>@upsidebrown, andrewbrownanimator.com</td></tr>
                    <tr><td className={styles.creditName}>Katherine Le</td><td>@kat_apult, @kat.apult_</td></tr>
                    <tr><td className={styles.creditName}>Isabella Spagnolo </td><td>@ladybugbells</td></tr>
                    <tr><td className={styles.creditName}>Jimoen Stallard</td><td>@jimen_salad</td></tr>
                    <tr><td className={styles.creditName}>Will Savage</td><td>@artwithsavage</td></tr>
                    <tr><td className={styles.creditName}>Oscar Ramos Ramudo</td><td>@ogbogs_art</td></tr>
                    <tr><td className={styles.creditName}>Elena Le</td><td>@vinaelenaa</td></tr>
                    <tr><td className={styles.creditName}>Stewart McQueen</td><td>@mcqueendraws, stewartmcqueen.weebly.com</td></tr>
                    <tr><td className={styles.creditName}>Connie Williams</td><td>@spindly.cow</td></tr>
                    <tr><td className={styles.creditName}>Tanapol Suriyophasakon</td><td>@tanzury</td></tr>
                    <tr><td className={styles.creditName}>Mitch Hearn</td><td>@mitchographer</td></tr>
                    <tr><td className={styles.creditName}>Nicholas Lazo</td><td>@nicklazoart</td></tr>
                    <tr><td className={styles.creditName}>David Goodman</td><td>@mousetrap7005</td></tr>
                    <tr><td className={styles.creditName}>Maia dG</td><td>@anotherfreakingartist, @MdGanimation</td></tr>
                    <tr><td className={styles.creditName}>Joel Wise</td><td>@joelwize_art</td></tr>
                    <tr><td className={styles.creditName}>Xinyuan Chen</td><td>@toy.hen</td></tr>
                    <tr><td className={styles.creditName}>Bengt Xylander</td><td>@bengt.illustration</td></tr>
                    <tr><td className={styles.creditName}>Eugene Chung</td><td>@eugeniusnius</td></tr>
                    <tr><td className={styles.creditName}>Amira Stanbouli</td><td>@amira_stanbouli</td></tr>
                    <tr><td className={styles.creditName}>Emily Campbell-Doan</td><td>@emilycdoan_animation</td></tr>
                    <tr><td className={styles.creditName}>Jasmine Lai</td><td>@jastea009</td></tr>
                    <tr><td className={styles.creditName}>Wil Kenny</td><td>@cuck.nuts</td></tr>
                    <tr><td className={styles.creditName}>Saif Khan</td></tr>
                    <tr><td className={styles.creditName}>Jemima Brooks</td><td>@tusky_mima</td></tr>
                    <tr><td className={styles.creditName}>Chegg!</td><td>@cheggimus</td></tr>
                    <tr><td className={styles.creditName}>Amy Djakovic</td><td>@vicious_hues, @vicious_hues_art</td></tr>
                    <tr><td className={styles.creditName}>Jay De Leon Lagman</td><td>@saturjaysunjay</td></tr>
                    <tr><td className={styles.creditName}>Benjamin Meyer</td><td>@benspr0utt</td></tr>
                    <tr><td className={styles.creditName}>Tyler Patten</td><td>@niceguytye</td></tr>
                    <tr><td className={styles.creditName}>Siann Lau</td><td>@ladysiann </td></tr>
                    <tr><td className={styles.creditName}>Alex Banks</td><td>@captain_omelette</td></tr>
                    <tr><td className={styles.creditName}>Katrina</td><td>@tinytako</td></tr>
                    <tr><td className={styles.creditName}>Tanya</td><td>@frozenspoots</td></tr>
                    <tr><td className={styles.creditName}>Summer Lloyd-Johnson</td><td>@miragemeld</td></tr>
                    <tr><td className={styles.creditName}>Alea Trinajstic</td><td>@alea.png, aleatrina.myportfolio.com</td></tr>
                    <tr><td className={styles.creditName}>Alice Nguyen</td><td>@applebuutt</td></tr>
                    <tr><td className={styles.creditName}>SunSmog</td><td>sunsmog.carrd.co</td></tr>
                    <tr><td className={styles.creditName}>Tim Fontes</td><td>@emptypixl</td></tr>
                    <tr><td className={styles.creditName}>Anthony Thanaxay</td><td>@hyodoko</td></tr>
                    <tr><td className={styles.creditName}>Emiko Seita </td><td>@mercuryandscry_, @mercury-and-scry</td></tr>
                    <tr><td className={styles.creditName}>Angie Ngo</td><td>@banjotempo</td></tr>
                    <tr><td className={styles.creditName}>Austen Armstrong</td><td>@a.armstrong.photos</td></tr>
                    <tr><td className={styles.creditName}>Beatrice Rose Robinson</td><td>@beatriceroserobinson, beatriceroserobinson.com</td></tr>
                    <tr><td className={styles.creditName}>Gabby Hawkey</td><td>@chaiwhisker, chaiwhisker.com</td></tr>
                    <tr><td className={styles.creditName}>Angelica Phanekham </td><td>@angi3hearti3_art</td></tr>
                    <tr><td className={styles.creditName}>Finnosho</td><td>@finnosho</td></tr>
                    <tr><td className={styles.creditName}>Jed Summers</td><td>@jedsumm, @jedsumm_</td></tr>
                    <tr><td className={styles.creditName}>Bianca Trani</td><td>@bbianca.jpg</td></tr>
                    <tr><td className={styles.creditName}>Vi-An Nguyen</td><td>@tulipfin</td></tr>
                    <tr><td className={styles.creditName}>Franki Furnell</td><td>@flopbears</td></tr>
                    <tr><td className={styles.creditName}>jamm</td><td>jarofjamm.com, @jarofjamm, @jarofjammdraws</td></tr>
                    <tr><td className={styles.creditName}>Olivia Hartanto</td><td>@PrincessAndTheToad7302</td></tr>
                    <tr><td className={styles.creditName}>Kym Checa</td><td>kymcheca.com</td></tr>
                    <tr><td className={styles.creditName}>Brienna McKenzie</td><td>@juice_popps </td></tr>
                    <tr><td className={styles.creditName}>Courtney Spencer</td><td>@arvemis, arvemis.com</td></tr>
                    <tr><td className={styles.creditName}>Lachlan Garrity</td><td>@lachlangarrity</td></tr>
                    <tr><td className={styles.creditName}>Timothy Merks</td><td>@timmerks</td></tr>
                    <tr><td className={styles.creditName}>Devon Xu</td><td>@devxoid</td></tr>
                    <tr><td className={styles.creditName}>Maxwell Sharpe</td><td>@max_r3lax, @max_r3lax_3D</td></tr>
                    <tr><td className={styles.creditName}>Nathalie Feodora</td><td>@redmageatelier</td></tr>
                    <tr><td className={styles.creditName}>Nikita Sutedjo</td><td>@akitales</td></tr>
                    <tr><td className={styles.creditName}>Marieka Walsh</td><td>mariekawalsh.com.au</td></tr>
                    <tr><td className={styles.creditName}>Nusha Wheeldon</td><td>@yeahnyah </td></tr>
                    <tr><td className={styles.creditName}>Matt Watson</td><td>mattwatsonillustration.com</td></tr>
                    <tr><td className={styles.creditName}>The Treehouse</td><td>@thetreehouse_animation, the-treehouse.co</td></tr>
                    <tr><td className={styles.creditName}>Waheeda Rahman-Mair</td><td>@waheeda_art, waheeda.co.uk</td></tr>
                    <tr><td className={styles.creditName}>Chris Rahman-Mair</td><td>@crm_camera</td></tr>
                    <tr><td className={styles.creditName}>Tim Sta-Ana</td><td>@timstaana</td></tr>
                    <tr><td className={styles.creditName}>Olga Chciherova</td><td>@chimyen, @birdiedraws</td></tr>
                    <tr><td className={styles.creditName}>Bryce Pemberton</td><td>@bpembs</td></tr>
                    <tr><td className={styles.creditName}>Lester</td><td>@lstrchn</td></tr>
                    <tr><td className={styles.creditName}>Adam Gillespie</td><td>@artofadamgillespie</td></tr>
                    <tr><td className={styles.creditName}>Tonia Ciccone</td><td>@toniaciccone_art</td></tr>
                    <tr><td className={styles.creditName}>Crystal Nguyen</td><td>@cytnguyen</td></tr>
                    <tr><td className={styles.creditName}>Shannimation</td><td>@shannanimation_bone</td></tr>
                    <tr><td className={styles.creditName}>Sohun Bae</td><td>@heonb22, heonbee.weebly.com</td></tr>
                    <tr><td className={styles.creditName}>Lucy Bell</td><td>@gooseyspen</td></tr>
                    <tr><td className={styles.creditName}>Wonsun Jin</td><td>@wonsunjin</td></tr>
                    <tr><td className={styles.creditName}>Theo S Dahlstrom</td><td>@theo.s.d</td></tr>
                    <tr><td className={styles.creditName}>Eleanor Face</td><td>@fatc.hele</td></tr>
                    <tr><td className={styles.creditName}>Marshella Gohan</td><td>@marziee.02</td></tr>
                    <tr><td className={styles.creditName}>Viggo Uno</td><td>@viggouno</td></tr>
                    <tr><td className={styles.creditName}>James Lindsay</td><td>@jmslndsy, jameslinds.com</td></tr>
                    <tr><td className={styles.creditName}>Tilli Merten</td><td>@tilli_mrtn</td></tr>
                    <tr><td className={styles.creditName}>Marc agosta</td><td>@marcjaarlz</td></tr>
                    <tr><td className={styles.creditName}>Svanna Christiansen</td><td>@swoonc.art</td></tr>
                    <tr><td className={styles.creditName}>Sen</td><td>@sen_blades, senblades.tumblr.com</td></tr>
                    <tr><td className={styles.creditName}>Jimmy Soligo</td><td>@curryricenstuff</td></tr>
                    <tr><td className={styles.creditName}>Nilufar Shah</td><td>@nilufar.exe</td></tr>
                    <tr><td className={styles.creditName}>Jacob Taylor</td><td>@jakemakesthings</td></tr>
                    <tr><td className={styles.creditName}>Paddy O'Brien</td><td>@dolphino_media, paddyobrienanimation.com</td></tr>
                    <tr><td className={styles.creditName}>Tom Groenestyn</td><td>@tom_groenestyn, @tomgeez_, tomgroenestyn.com </td></tr>
                    <tr><td className={styles.creditName}>Jon Snow </td><td>@synnwren</td></tr>
                    <tr><td className={styles.creditName}>Alexandra Tretiak</td><td>alexandratretiak.com, @alexandratretiak</td></tr>
                    <tr><td className={styles.creditName}>Steve Do</td><td>@advicecatt</td></tr>
                    <tr><td className={styles.creditName}>Ricardo Gómez</td><td>@ricardo_gomez_art</td></tr>
                    <tr><td className={styles.creditName}>William Xu</td><td>@w_xuart</td></tr>
                    <tr><td className={styles.creditName}>Elana Kwong</td><td>@nightpest</td></tr>
                    <tr><td className={styles.creditName}>Julia van Oppen</td><td>@lukalunar</td></tr>
                    <tr><td className={styles.creditName}>sailorgouda</td><td>sailorgouda.newgrounds.com</td></tr>
                    <tr><td className={styles.creditName}>Johnny Pratthana</td><td>iohmy.carrd.co, @iohmy_</td></tr>
                    <tr><td className={styles.creditName}>Winston Liu</td><td>@pakofever</td></tr>
                    <tr><td className={styles.creditName}>pikkentt</td><td>@pikkentt</td></tr>
                    <tr><td className={styles.creditName}>Han Nguyen</td><td>@aeitchen</td></tr>
                    <tr><td className={styles.creditName}>Klara Mae</td><td>@telemartus</td></tr>
                    <tr><td className={styles.creditName}>William Ng</td><td>@nowilltodraw</td></tr>
                    <tr><td className={styles.creditName}>Axel Lawrence Nygaard</td><td>axelaw-art.com, @axe_law</td></tr>
                    <tr><td className={styles.creditName}>Daze</td><td>@dazefrilly</td></tr>
                    <tr><td className={styles.creditName}>Yuna Jang</td><td>@silentaccio</td></tr>
                    <tr><td className={styles.creditName}>Gabriel Robinson</td><td>gabrielrobinson.com, @gaberobinsonfilm</td></tr>
                    <tr><td className={styles.creditName}>Robin Eide</td><td>@robineide_art, @robeans_art</td></tr>
                    <tr><td className={styles.creditName}>Chloe Lambley</td><td>@clamb_art</td></tr>
                    <tr><td className={styles.creditName}>Chloe Spanos</td><td>@kitschky</td></tr>
                    <tr><td className={styles.creditName}>Andrew Leonard</td><td>@artworksbyandy</td></tr>
                    <tr><td className={styles.creditName}>Gerry Lin</td><td>@gerrylin</td></tr>
                    <tr><td className={styles.creditName}>Holly May Fletcher</td><td>@mawok </td></tr>
                    <tr><td className={styles.creditName}>Diego Luna </td><td>@dmoona26</td></tr>
                    <tr><td className={styles.creditName}>Charlene Ding</td><td>charleneding.com, @_charleneding_</td></tr>
                    <tr><td className={styles.creditName}>Nathaniel Lym </td><td>@artsylym</td></tr>
                    <tr><td className={styles.creditName}>Jacqueline Qin (@flumazee)</td><td>@flumazee</td></tr>
                    <tr><td className={styles.creditName}>Nik Sutila</td><td>@mrnik_art</td></tr>
                    <tr><td className={styles.creditName}>Atticus Gough</td><td>@atti.ink</td></tr>
                    <tr><td className={styles.creditName}>Brandon Tannoury</td><td>@brandont_look_at_this</td></tr>
                    <tr><td className={styles.creditName}>Alyssa Mullen</td><td>@heygremlin</td></tr>
                    <tr><td className={styles.creditName}>Haidi Yan</td><td>@sleepistar</td></tr>
                    <tr><td className={styles.creditName}>Noura Hijazi</td><td>@noura.h.art</td></tr>
                    <tr><td className={styles.creditName}>Em Brennan (Scribe_less)</td><td>@scribe_less, @scribeless</td></tr>
                    <tr><td className={styles.creditName}>Kate Darwell</td><td>@wiile.s</td></tr>
                    <tr><td className={styles.creditName}>vivien chiu</td><td>@livelytangent</td></tr>
                    <tr><td className={styles.creditName}>Hero Hendel</td><td>@h.chumiti </td></tr>
                    <tr><td className={styles.creditName}>Christopher Salloum</td><td>@art.salloum, @salloumart</td></tr>
                    <tr><td className={styles.creditName}>Saachi</td><td>@angels_changeling, saachio.wixsite.com/website</td></tr>
                    <tr><td className={styles.creditName}>Khoo Kai Ning</td><td>@khoo_kn, khoo_kn.artstation.com</td></tr>
                    <tr><td className={styles.creditName}>Luis Lagman</td><td>@pio_hag</td></tr>
                    <tr><td className={styles.creditName}>Amritha Rajesh</td><td>@k.a.c.h.e.w</td></tr>
                    <tr><td className={styles.creditName}>Sebester</td><td>@sebsm_campos, @sebester</td></tr>
                    <tr><td className={styles.creditName}>Shayla Nguyen</td><td>@cinnavee</td></tr>
                    <tr><td className={styles.creditName}>Fransisca "Lyn" Wijaya</td><td>@nyolusz</td></tr>
                    <tr><td className={styles.creditName}>Marcy Davies</td><td>@westerndevilmedia_</td></tr>
                    <tr><td className={styles.creditName}>Joe Idosaka</td><td>@jidska, joeidosaka.com </td></tr>
                    <tr><td className={styles.creditName}>Jessica Edge</td><td>superredhead.com @super_redhead</td></tr>
                    <tr><td className={styles.creditName}>Henry Lund</td><td>@henry_lund_art</td></tr>
                    <tr><td className={styles.creditName}>Vittorio Suhendro</td><td>@vitz.art</td></tr>
                    <tr><td className={styles.creditName}>Olivia stewart</td><td>@ArkleAnim</td></tr>
                    <tr><td className={styles.creditName}>Brandon Pen</td><td>@armisaelani</td></tr>
                    <tr><td className={styles.creditName}>Jasper Ly</td><td>@dapperjap</td></tr>
                    <tr><td className={styles.creditName}>Sean Liao</td><td>@art_of_osprie</td></tr>
                    <tr><td className={styles.creditName}>Diogo Alves</td><td>@diogomdalves</td></tr>
                    <tr><td className={styles.creditName}>Daisy Allen</td><td>@daisymaisysart</td></tr>
                    <tr><td className={styles.creditName}>Miles Mazzocato </td><td>@milesmazzocato</td></tr>
                    <tr><td className={styles.creditName}>Jasmine Auchterlonie</td><td>@jasmine_auc</td></tr>
                    <tr><td className={styles.creditName}>blackjingertee</td><td>@blackjingertee</td></tr>
                    <tr><td className={styles.creditName}>Liam Waterhouse</td><td>@blinkingmanually</td></tr>
                    <tr><td className={styles.creditName}>Remi-Rose</td><td>@remrose_anim</td></tr>
                    <tr><td className={styles.creditName}>billy rout</td><td>@bilb0h</td></tr>
                    <tr><td className={styles.creditName}>Trotsworth</td><td>@thetrotsworth</td></tr>
                    <tr><td className={styles.creditName}>Mauricio Cesana</td><td>@inspirandom, inspirandom.com</td></tr>
                    <tr><td className={styles.creditName}>Heather-Renay "Nezu" Williamee</td><td>@williamee.dessinee</td></tr>
                </tbody>
            </table>
            <div>
                <img className={styles.screencapL} src={screenCap0} style={{bottom: "70%"}}></img>
                <img className={styles.screencapR} src={screenCap1} style={{bottom: "62%"}}></img>
                <img className={styles.screencapL} src={screenCap2} style={{bottom: "54%"}}></img>
                <img className={styles.screencapR} src={screenCap3} style={{bottom: "46%"}}></img>
                <img className={styles.screencapL} src={screenCap4} style={{bottom: "38%"}}></img>
                <img className={styles.screencapR} src={screenCap5} style={{bottom: "30%"}}></img>
                <img className={styles.screencapL} src={screenCap6} style={{bottom: "22%"}}></img>
                <img className={styles.screencapR} src={screenCap7} style={{bottom: "14%"}}></img>
            </div>
            
            <div id={stylesMonkey.ImageParent}>
                <img id={stylesMonkey.gangImage }src={gangImg} alt="Gang"></img>
                <img id={stylesMonkey.catFishImage }src={catFishImg} alt="Catfish"></img>
                <img id={stylesMonkey.volcanoImage} src={volcano} alt="Volcano"></img>
            </div>
            
            
            <Footer/>
        </>
    )
}

export default Monkey;