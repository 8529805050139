import React from "react";
import { NavLink } from "react-router-dom";
import styles from '../CSS/Navbar.module.css';

const Navbar = () => {
    return(
        <div id={styles.navbar}>
            <h1 id={styles.header}>
                <NavLink
                    to="/"
                    id={styles.reanimated}
                >
                    REANIMATED.AU
                </NavLink>
            </h1>

            <div id={styles.navBtns}>
                <NavLink
                    to="/"
                    className={({ isActive }) => (isActive ? styles.active : styles.inactive)}
                    id={styles.home}
                >
                    Home
                </NavLink>
                <NavLink
                    to="/about"
                    className={({ isActive }) => (isActive ? styles.active : styles.inactive)}
                    id={styles.about}
                >
                    About
                </NavLink>
                
        

                <NavLink
                    to="/Monkey"
                    className={({ isActive }) => (isActive ? styles.active : styles.inactive)}
                    id={styles.monkey}
                >
                    2024
                </NavLink>
                <NavLink
                        to="/JapaneseSpiderman"
                        className={({ isActive }) => (isActive ? styles.active : styles.inactive)}
                        id={styles.spiderman}
                    >
                        2023
                </NavLink>
                    <NavLink
                        to="/Batman"
                        className={({ isActive }) => (isActive ? styles.active : styles.inactive)}
                        id={styles.batman}
                    >
                        2022
                </NavLink>

                
            </div>
        </div>
    )
}

export default Navbar;