import React from "react";
import magic1 from './Media/MonkeyReanim_LOGO.png'
import monkeyGang from './Media/gang1.png'
import Navbar from '../Components/Navbar';
import Footer from "../Components/Footer";
import styles from '../CSS/Index.module.css';
import qrPlaceholder from './Media/qr placeholder.jpg';
import { NavLink } from "react-router-dom";
import batmanLogo from './Media/batman banner.png'
import spidermanLogo from './Media/spiderman banner.png'
import poster from './/Media/monkeyreanim_screening_WIDE.png';

const Home = () => {
    return (
        <>
            <Navbar/>

            
            <div className={styles.container}>
                <img id={styles.monkeyGang} src={monkeyGang} alt="Monkey Gang"/>

                <img id={styles.monkeyText} src={magic1} alt="Monkey Reanimated Logo"/>
                
            </div>
            <div id={styles.scrollContainer}>
                <NavLink
                to="/Monkey" className={styles.linkScrolling}
                >
                    <span>OUT NOW - OUT NOW - OUT NOW - OUT NOW - OUT NOW - OUT NOW - OUT NOW - OUT NOW - OUT NOW - OUT NOW - OUT NOW - OUT NOW - OUT NOW - OUT NOW - OUT NOW - OUT NOW - OUT NOW - OUT NOW - OUT NOW - OUT NOW - </span>
                </NavLink>
            </div>

            {/*<div className={styles.imageButtonContainer}>
                <img className={styles.imageButton} src={batmanLogo}></img>
                <img className={styles.imageButton} src={spidermanLogo}></img>
    </div>*/}

            <img id={styles.posterImage} src={poster} alt="Monkey reanimated poster"></img>


            <section className={styles.indexText}>
                <div className={styles.innerText}>

                    <p>Hi- We are Reanimated AU. We take campy pieces of live action media, chop them into bits and send those bits out to animators across Australia and the world. These talented animators who volunteer to be on each project do, well, whatever the hell they want with it, (Usually something hilarious, charming, and oddly beautiful.) </p>
                    <p>We’ve had our quirky clips translated into 3D animation, 2D animation, stop motion and even puppetry. Once we get all the work back from our talented animators, we stitch up our beautiful Franken-animated episode. We showcase it in screenings around Australia, and bask in the glow of beautiful work made by amazing people.</p>
                    <p>We run one project each year over the course of 3 months- if you want to see our previous work <NavLink className={styles.link} to='/japanesespiderman'>click here</NavLink>, and <NavLink className={styles.link} to='/batman'>here!</NavLink> If you want to sign up for our current project, Monkey Reanimated, <NavLink className={styles.link} to='/monkey'>click here!</NavLink> If you want to contact us, email us at '<b>reanimationstation123@gmail.com</b>'.</p>
                    <p>Have fun looking around the website and at everyone’s work- we hope you love it.</p>
                    <p className={styles.signOff}>-Reanimated Team</p>
                </div>
            </section>
            

            <Footer/>
        </>
    );
};
 
export default Home;