import React from "react";
import Navbar from '../Components/Navbar';
import Footer from "../Components/Footer";
import styles from '../CSS/About.module.css';
import Profile from "../Components/Profile";
import mingusImage from './Media/mingusHead.png';
import lachlan from './Media/GarritysExcelDocumentOfWonders.png';
import wilton from './Media/Wilto.png';
import remi from './Media/remi.png';
import amira from './Media/Amira.png';
import kate from './Media/Kate_crop.png'
 
const About = () => {
    return (
        <>
            <Navbar/>

            

            <section id={styles.body}>
                <div className={styles.aboutText}>
                    <h2 className={styles.heading2}>The Reanimated Team</h2>
                        <p className={styles.aboutP}>Hey! 
                            We're Reanimated, a Sydney-based team focused on re-animating live-action t.v. shows of the past! Since 2022 we've run the project anually to great success, ending each year with in-person screenings open to all! Our aim is to build up a community within the Australian animation scene, providing opportunity for animators of all disciplines and experience levels to participate in a fun project where they can build their skills, share their creations and collaborate.</p>
                        <p className={styles.aboutP}>Our friends at <a className={styles.aboutLink} href="https://www.knoxstreetbar.com/">Knox Street Bar</a> have been the gracious hosts of our Sydney-based screenings since the very beginning, allowing attendees to grab a friend, a drink and a seat to sit and enjoy the show. </p>

                        <p className={styles.aboutP}>Additional thanks to <a className={styles.aboutLink} href="https://www.mylovercindi.net/">My Lover Cindi</a> for allowing Reanimated to make its interstate debut to Adelaidean animators and admirers alike.</p>
                </div>
                <ul id={styles.profileFlex}>

                    <Profile name={'Wilton Lander'} social={'@wilton.anim'} mingus={wilton} link ={'https://www.instagram.com/wilton.anim/'}></Profile>
                    <Profile name={'Remi-Rose'} social={'@remrose_anim'} mingus={remi} link ={'https://www.instagram.com/remrose_anim/'}></Profile>
                    {/*<Profile name={'Drakomingus'} social={'mingus@BG.com'} mingus={mingusSketch} color={'palevioletred'} color2={'#8B5664'}></Profile>*/}
                    <Profile name={'Amira Stanbouli'} social={'@amira_stanbouli'} mingus={amira} link={'https://www.instagram.com/amira_stanbouli/'}> </Profile>
                    <Profile name={'Lachlan Garrity'} social={'@lachlangarrity'} mingus={lachlan} link ={'https://www.instagram.com/lachlangarrity/'}></Profile>
                    <Profile name={'Kate Darwell'} social={'@wiile.s'} mingus={kate} link ={'https://www.instagram.com/wiile.s/'}></Profile>
                </ul>
                
            </section>

            <Footer/>
        </>
    );
};
 
export default About;